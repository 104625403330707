<template>
    <div>
        <div class="row vh-100 overflow-x-hidden" v-if="isLoginRegisterPage">
            <div class="col-6 p-0 bg-cover-center ml-minus-5px d-none d-lg-block login-right-img"
                 v-bind:style="{'background-image':'url(' + require('@/assets/img/login-right-bg.jpg')+')'}">
                <div class="login-text">
                    {{ $t('bahcesehir_university') }} <br>
                    {{ $t('turkmer') }} {{ $t('application_system') }}
                </div>
            </div>
            <div class="col-12 col-lg-6 bg-white rounded-lg mr-0 mr-md-minus-5px shadow">
                <div class="d-flex flex-column h-100">
                    <div class="flex-grow-1 w-100 d-flex flex-row align-items-center">
                        <slot></slot>
                    </div>
                    <div class="language mx-auto d-flex mb-4">
                        <a class="small text-center d-block mx-2 cursor-pointer"
                           :class="$i18n.locale=='tr' ? 'text-muted':'text-body'"
                           @click="changeLocale('tr')">
                            Türkçe
                        </a>
                        <a class="small text-center d-block mx-2 cursor-pointer  text-body"
                           :class="$i18n.locale=='en' ? 'text-muted':'text-body'"
                           @click="changeLocale('en')">
                            English
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <div class="d-flex vh-100 overflow-x-hidden" v-else>
            <div class="pr-lg-0 p-0 bg-cover-center login-right-img ml-0 d-none d-lg-flex"
                 style="flex-basis:350px; min-width: 350px;"
                 :style="{'background-image':'url(' + require('@/assets/img/login-right-bg.jpg')+')'}">
                <div class="login-text font-size-2 px-5" style="width:350px; ">
                    {{ $t('bahcesehir_university') }} <br>
                    {{ $t('turkmer') }} {{ $t('application_system') }}
                </div>
            </div>
            <div class="flex-grow-1 p-0 vh-100 overflow-y-scroll">
                <header class="header">
                    <b-navbar class="p-0">
                        <b-collapse id="nav-collapse" is-nav>
                            <b-collapse id="nav-collapse" is-nav class="d-block">
                                <div class="mb-0 ml-5 bg-transparent small text-uppercase p-0 w-100">
                                    <h5 class="m-0 mr-5 d-flex align-items-center">
                                        <span class="d-block d-md-none">
                                            {{ $t('turkmer').toUpper() }}<br>{{ $t('application_system').toUpper() }}
                                        </span>
                                        <span class="d-none d-md-block">
                                            {{ $t('turkmer').toUpper() }} {{ $t('application_system').toUpper() }}
                                        </span>
                                    </h5>
                                </div>
                                <b-navbar-nav class="ml-0 ml-lg-auto pl-4 pl-lg-0 py-4 pr-4 py-lg-5 pr-lg-5 justify-content-end">
                                    <div class="d-flex">
                                        <b-button variant="outline-danger" @click="logout">
                                            <i class="ri-logout-circle-line mr-1 top-minus-1 align-middle"></i>
                                            {{ $t('logout') }}
                                        </b-button>
                                    </div>
                                </b-navbar-nav>
                            </b-collapse>
                        </b-collapse>
                    </b-navbar>
                </header>
                <div class="height-80 mr-minus-15 bg-cover-center login-right-img ml-0 position-relative d-lg-none"
                     v-bind:style="{'background-image':'url(' + require('@/assets/img/login-right-bg.jpg')+')'}">
                    <div class="login-text px-5" style="font-size: 1.3rem">
                        {{ $t('bahcesehir_university') }} <br>
                        {{ $t('turkmer') }} {{ $t('application_system') }}
                    </div>
                </div>
                <div class="p-2 p-md-3 p-lg-5">
                    <slot></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        computed: {
            isLoginRegisterPage() {
                let links = [
                    '/turkmer/login',
                    '/turkmer/register',
                    '/turkmer/forgot-password'
                ]
                return links.includes(this.$route.path);
            },
            locale() {
                return this.$store.getters['getLocale']
            }
        },
        beforeCreate() {
            this.$store.dispatch('turkmerApplication/initData');
        },
        methods: {
            changeLocale(locale) {
                this.$i18n.locale = locale;
                this.$store.commit('setLocale', locale)
                // localeChanged();
            },
        }
    }
</script>
